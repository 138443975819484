.instagram_container {
  width: 100%;
  padding: 0 20px;
}
.instagram_container h2 {
  font-family: "Montserrat", sans-serif;
  font-size: 42px;
  text-align: center;
  margin: 50px 0;
}
.instagram_container h2 span {
  color: #ec1a6c;
}
.instagram_post_outer_container {
  width: 100%;
  overflow-x: hidden;
  margin-bottom: 40px;
  position: relative;
}
.instagram_post_inner_container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.instagram_post_inner_container .post {
  flex: 0 0 440px;
}
.instagram_post_inner_container .post img {
  width: 100%;
}
.instagram_controls {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ins_control {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ec1a6c;
}
.ins_control img {
  height: 35px;
}
.ins_left_control img {
  position: relative;
  right: 2px;
}
.ins_right_control img {
  position: relative;
  left: 2px;
}
@media only screen and (max-width: 768px) {
  .instagram_container h2 {
    font-size: 36px;
  }
  .instagram_post_inner_container .post {
    flex: 0 0 360px;
  }
  .ins_control {
    width: 60px;
    height: 60px;
  }
  .ins_control img {
    height: 28px;
  }
}
@media only screen and (max-width: 600px) {
  .instagram_controls {
    padding: 0;
  }
  .instagram_container h2 {
    font-size: 28px;
  }
  .ins_control {
    width: 50px;
    height: 50px;
  }
  .ins_control img {
    height: 22px;
  }
}

.textpink {
  color: #ec1a6c;
}
