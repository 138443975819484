.contact .first {
  padding: 150px 0;
  text-align: center;
  color: white;
  font-size: 48px;
  background-color: rgba(236, 26, 108, 0.45);
  background-position: center;
  background-size: cover;
  background-blend-mode: overlay;
}
#tfirst {
  background-image: url(./../../assets/img/contact_us_bg.png);
}
#tfoodtruck {
  background-image: url(./../../assets/img/foodtruckbg2.png);
}
@media only screen and (max-width: 1000px) {
  .contact .first {
    font-size: 40px;
  }
}
@media only screen and (max-width: 768px) {
  .contact .first {
    font-size: 36px;
  }
}
@media only screen and (max-width: 600px) {
  .contact .first {
    font-size: 28px;
    padding: 120px 0;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@700&display=swap");

.contact .second {
  padding: 65px;
  text-align: center;
  background-image: url(./../../assets/img/contact_us_bg2.png);
  background-repeat: no-repeat;
  background-position: bottom right;
}
.contact .form {
  display: flex;
  justify-content: center;
  text-align: center;
}
.contact .form_container {
  background-color: #fccc2d;
  padding: 50px;
  border-radius: 25px;
  width: 800px;
}
.contact .form_container h2 {
  font-size: 52px;
  font-family: "Roboto Condensed", sans-serif;
  color: #ff156d;
  margin-bottom: 15px;
}
.contact .form_container p {
  font-size: 28px;
  font-family: "Work Sans", sans-serif;
  font-weight: 600;
  margin-bottom: 35px;
}
.contact .form_container form {
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 30px;
  max-width: 830px;
}
.mb-50 {
  margin-bottom: 50px;
}
.contact .form_group {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 30px;
  overflow: hidden;
}
.contact .form_container input {
  flex-grow: 1;
  background: none;
  border: none;
  border-bottom: 1px solid #000;
  padding: 10px;
  max-width: 365px;
  padding-left: 0;
  outline: none;
  font-size: 20px;
  color: #000;
}
.contact .form_container input::placeholder {
  font-size: 20px;
  color: #000;
  font-family: "Work Sans", sans-serif;
}
.contact .form_container input[type="date"]::placeholder {
  font-size: 20px;
  color: #000;
  font-family: "Work Sans", sans-serif;
}
.contact .form_container select,
option {
  flex-grow: 1;
  background: none;
  border: none;
  border-bottom: 1px solid #000;
  padding: 10px;
  padding-left: 0;
  outline: none;
  font-size: 20px;
  color: #000;
}
#message {
  max-width: none;
}
.contact .form_container button {
  background: #ff156d;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  letter-spacing: 3px;
  font-weight: bold;
  padding: 25px 40px;
  color: white;
  border-radius: 60px;
  margin-top: 40px;
  width: 100%;
  max-width: 440px;
  box-shadow: 0px 14px 34px rgba(79, 60, 0, 0.2);
}

.contact .second_2 {
  max-width: 830px;
  margin: 0 auto;
  padding-top: 60px;
}
.contact .second_2 p {
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  line-height: 28px;
}
.contact .second_2 .social_icon {
  display: flex;
  justify-content: center;
  grid-gap: 50px;
  padding: 50px 0;
  border-bottom: 1px solid #ff156d;
}

.contact .contact_detail {
  margin-top: 30px;
  font-family: "Montserrat", sans-serif;
}
.contact .contact_detail h2 {
  font-size: 36px;
  color: #ff156d;
  letter-spacing: 1.5px;
  font-weight: bold;
  margin-bottom: 20px;
}
.contact .contact_detail a {
  font-size: 22px;
  letter-spacing: 1.5px;
  font-weight: 600;
}

@media only screen and (max-width: 950px) {
  .contact .form_group {
    flex-wrap: wrap;
    margin-bottom: 0;
    gap: 0;
  }
  .contact .form {
    max-width: 100%;
    padding: 20px;
  }
  .contact .form_container input,
  select {
    max-width: none;
    flex: 0 0 100%;
    margin-bottom: 50px;
  }
}
@media only screen and (max-width: 768px) {
  .contact .form_container button {
    font-size: 18px;
    letter-spacing: 2px;
    margin-top: 0px;
  }
}
.contact .form {
  max-width: 100%;
  padding: 20px;
}
@media only screen and (max-width: 600px) {
  .contact .second {
    padding: 50px 20px;
  }
  .contact .form {
    max-width: 100%;
    padding: 15px;
  }
  .contact .form_container {
    padding: 30px;
  }
  .contact .form_container h2 {
    font-size: 40px;
  }
  .contact .form_container p {
    font-size: 22px;
    margin-bottom: 30px;
  }
  .contact .form_container form {
    margin-top: 30px;
  }
  .contact .form_container input,
  select {
    margin-bottom: 25px;
    flex: 0 0 100%;
    font-size: 18px;
  }
  .contact .form_container select,
  option {
    margin-bottom: 25px;
    flex: 0 0 100%;
    font-size: 18px;
  }
  .contact .form_container input::placeholder {
    font-size: 18px;
    color: #000;
  }
  .contact .form_container input[type="date"]::placeholder {
    font-size: 18px;
    color: #000;
  }
  .contact .form_container button {
    font-size: 18px;
    padding: 20px 30px;
    margin-top: 20px;
  }
  .contact .second_2 {
    padding-top: 50px;
  }
  .contact .second_2 p {
    font-size: 18px;
  }
  .contact .second_2 .social_icon {
    padding: 40px 0;
  }
  .contact .contact_detail h2 {
    font-size: 32px;
  }
  .contact .contact_detail a {
    font-size: 20px;
  }
}
